import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Page404Component} from "./root/page/page-404/page-404.component";
import {AuthGuard} from "@auth0/auth0-angular";

const routes: Routes = [
  {path: '', loadChildren: () => import('./static/static.module').then(m => m.StaticModule)},
  {path: 'dash', canActivate: [AuthGuard], loadChildren: () => import('./dash/dash.module').then(m => m.DashModule)},
  {path: '**', component: Page404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
