export const environment = {
  services: {
    user: 'https://e0pbvrsvx8.execute-api.us-east-1.amazonaws.com'
  },
  auth: {
    domain: 'mterm-dev.us.auth0.com',
    clientId: 'VT8w3y1mAokZWTBNpfFOTvC4KhCFqbmC',
    authorizationParams: {
      audience: 'https://mterm-dev.users',
      redirect_uri: window.location.origin
    }
  }
};
