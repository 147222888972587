import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {catchError, map, mergeMap, Observable, of, tap} from "rxjs";
import {AuthService} from "@auth0/auth0-angular";

export interface Session {
  loggedIn: boolean;
  token: string;
  user: {
    user_id: string,
    nickname: string,
    picture: string,
    name: string,
    user_email: string
  }
}

const EMPTY_SESSION: Session = {
  loggedIn: false,
  token: "",
  user: {
    user_id: "",
    user_email: "",
    nickname: "",
    picture: "",
    name: "",
  }
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private auth: AuthService,
              private http: HttpClient) {

  }

  public session$: Observable<Session> = this.session();

  public authenticated$ = this.session$.pipe(
    map(session => session.loggedIn)
  );

  public user$ = this.session$.pipe(
    map(session => session.user)
  );

  public picture$ = this.user$.pipe(
    map(user => user.picture)
  );

  public nickname$ = this.user$.pipe(
    map(user => user.nickname)
  );

  public email$ = this.user$.pipe(
    map(user => user.user_email)
  );

  /**
   * Retrieves the user session either from local storage or by fetching it from the server.
   * @returns {Observable<Session>} An observable that emits the user session.
   */
  session(): Observable<Session> {
    /**
     * Retrieves the session item from local storage.
     * @type {string | null}
     */
    const sessionItem = localStorage.getItem("session");

    if (sessionItem) {
      /**
       * If the session item exists in local storage, checks the authentication status.
       * @type {Observable<Session>}
       */
      return this.auth.isAuthenticated$.pipe(
        /**
         * Maps the authentication status to the session object.
         * If authenticated, parses the session item from local storage and sets loggedIn to true.
         * If not authenticated, returns an empty session.
         * @param {boolean} isAuth - The authentication status.
         * @returns {Session} The session object.
         */
        map(isAuth => isAuth ? { ...JSON.parse(sessionItem), loggedIn: true } as Session : EMPTY_SESSION)
      );
    }

    /**
     * If the session item doesn't exist in local storage, checks the authentication status.
     * @type {Observable<Session>}
     */
    return this.auth.isAuthenticated$.pipe(
      mergeMap(isAuthenticated => {
        if (isAuthenticated) {
          /**
           * If authenticated, fetches the user session from the server.
           * @returns {Observable<Session>} An observable that emits the user session.
           */
          return this.fetchUserSession();
        }
        /**
         * If not authenticated, returns an empty session.
         * @returns {Observable<Session>} An observable that emits an empty session.
         */
        return of(EMPTY_SESSION);
      })
    );
  }

  /**
   * Fetches the user session by retrieving the access token and making an HTTP request to get the user details.
   * @private
   * @returns {Observable<Session>} An observable that emits the user session.
   */
  private fetchUserSession(): Observable<Session> {
    /**
     * Retrieves the access token silently using the authentication service.
     * @type {Observable<string>}
     */
    return this.auth.getAccessTokenSilently().pipe(
      mergeMap((token: string) => {
        /**
         * Makes an HTTP GET request to retrieve the user details using the access token.
         * @type {Observable<any>}
         */
        return this.http.get(`${environment.services.user}/me`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).pipe(
          /**
           * Maps the user details and token to create a session object.
           * @param {any} user - The user details returned from the API.
           * @returns {Session} The session object.
           */
          map((user: any) => ({
            token,
            loggedIn: true,
            user: user
          })),
          /**
           * Catches any errors that occur during the HTTP request.
           * @param {any} error - The error object.
           * @returns {Observable<Session>} An observable that emits an empty session.
           */
          catchError(error => {
            console.error(error);
            return of(EMPTY_SESSION);
          }),
          /**
           * Performs a side effect by storing the session object in local storage.
           * @param {Session} session - The session object.
           */
          tap(session => localStorage.setItem("session", JSON.stringify(session)))
        );
      })
    );
  }

}
