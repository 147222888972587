import {Component, Inject} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {DOCUMENT} from "@angular/common";
import {Session, UserService} from "../../user.service";
import {Observable} from "rxjs";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  constructor(@Inject(DOCUMENT) private doc: Document,
              public auth: AuthService,
              public user: UserService) {}

  session: Observable<Session> = this.user.session();

  profileImage: string = '';

  ngOnInit() {
    this.user.picture$.subscribe(imageUrl => {
      this.profileImage = imageUrl;
    })
  }

  login() {
    this.auth.loginWithRedirect();
  }

  logout(): void {
    localStorage.setItem("session", "")
    this.auth.logout({
      logoutParams: {
        returnTo: this.doc.location.origin
      }
    });
  }
}
